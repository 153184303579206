<template>
   <v-app>
      <v-main>
         <v-form ref="form" @submit.prevent="submit" class="d-flex justify-center align-center">
            <v-card dark color="rgba(0, 0, 0, 0.6)" width="480">
               <v-card-title>Lupa Password</v-card-title>
               <v-card-text>
                  Masukan email anda untuk memulai proses pemulihan password
               </v-card-text>
               <v-card-text class="px-10">
                  <v-text-field append-icon="mdi-account" label="Email" type="text" v-model="fm.email" :rules="emailRules" />
               </v-card-text>
               <v-card-actions class="pb-8 justify-space-around">
                  <v-btn text color="lime" to="/login" >login</v-btn>
                  <v-btn outlined rounded width="120px" color="primary" type="submit" :loading="loading">submit</v-btn>
               </v-card-actions>
            </v-card>
         </v-form>
      </v-main>
      <alert />
   </v-app>
</template>

<script>
import api from '../api';
import Alert from '../components/Alert.vue';
export default {
   components: { Alert },
   data: () => ({
      fm: {},
      emailRules: [v => !!v || 'Email harus diisi', v => /.+@.+\..+/.test(v) || 'Email tidak valid'],
      loading: false,
      token: null,
   }),
   mounted() {
   },
   methods: {
      submit() {
         if(!this.$refs.form.validate()) return;
         this.loading = true;
         api.create('recover_password',this.fm).then(({error, success}) => {
            if(error) {
               this.$alert({title: 'Ooops!', text: error, type: 'warning'});
               this.fm.password = '';
            } else {
               this.$alert({
                  type: 'success',
                  title: 'Success',
                  text: success,
                  timer: 3000,
                  ok: () => {this.$router.push('/login')}
               });
            }
         },
         () => {
            this.$alert('Ooops!', 'Terjadi kesalahan', 'error');
         }).finally(() => { this.loading = false });
      },
      checkPwd(v) {
         return v === this.fm.password || 'Kedua password tidak sama';
      },
   }
}
</script>
<style lang="css" scoped>
#app::before {
   content: '';
   position: fixed;
   width: 100%;
   height: 100%;
   top: 0;
   left: 0;
   background: url("../assets/bg.jpg") no-repeat center center;
   background-size: cover;
   opacity: .5;
   will-change: transform;
   /* z-index: -1; */
}
form {height: 100%;}
</style>